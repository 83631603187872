* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    user-select: none;
}

@media (max-width:520px) {
    body {
        font-size: 12px;
    }
}

/* registration page */
.form-container {
    width: 100%;
    height: calc(100vh - 60px);
    background-position: top;
    background-size: cover;

    display: flex;
    justify-content: center;
    align-items: center;

    /* border: 2px solid red; */
}

.signup-form {
    width: 50%;
    max-width: 550px;
    height: 75vh;
    padding: 50px 20px;
    background: rgba();

    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 50px;
}

.form-inputs-wrapper {
    width: 80%;
    height: 45px;
}

.form-inputs-wrapper>input {
    width: 100%;
    height: 100%;
    padding: 0 2em;

    border: none;
    border-bottom: thin solid gray;
}

form>button {
    font-size: 20px;
    width: 250px;
    height: 50px;
    cursor: pointer;
    border: none;

}

/* login page */

.login-form {
    width: 30%;
    min-width: 400px;
    height: 350px;
    padding: 2em 0;

    box-shadow: 0 0 10px 0.1px rgba(0, 0, 0, 0.2);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 30px;
}

.login-form>div {
    width: 80%;
    height: 50px;
}

.login-form>div>input {
    width: 100%;
    height: 100%;
    padding: 0 2em;

    border: none;
    border-bottom: thin solid gray;
}

/* home page styling */
/* navBar */

.nav-wrapper {
    width: 100vw;
    height: 60px;

    position: fixed;
    top: 0;
    z-index: 2;
}

nav {
    width: 100vw;
    height: 60px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7));
    border-bottom: thin solid red;



    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-contents-wrapper {
    height: 100%;
    width: 80%;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width:330px) {
    .brand-caption {
        display: none;
    }
}

@media (max-width:520px) {
    .nav-contents-wrapper {
        width: 97%;
    }
}

.cart {
    min-width: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 2px solid red;
}

.cart>h3 {
    position: relative;
}

#cart-badge {
    font-size: .8rem;
    text-align: center;
    padding: .1em;
    border-radius: 10px;
    background: rgba(255, 0, 0, 0.9);


    position: absolute;
    right: -7px;
    top: -3px;
}

/* banner */
.banner {
    width: 100vw;
    height: 50vh;

    background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("../images/bg/jonathan-francisca-YHbcum51JB0-unsplash\ \(1\).jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    position: relative;
}

.banner-text-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    width: 60%;
    max-width: 750px;
    background: rgba(0, 0, 0, 0.2);
    padding: .5em .2em;

    color: white;
    text-align: center;

}

@media (min-width:500px) {
    .banner-text-wrapper {
        height: 300px;
    }
}

@media (max-width:500px) {
    .banner-text-wrapper {
        width: 330px;
    }
}

@media (max-width:330px) {
    .banner-text-wrapper {
        width: 90%;
    }
}

.header-text {
    font-size: 2.5rem;
}

.shop-now>h3 {
    display: inline-block;

    font-size: 1.8em;
    background: rgba(34, 0, 0, 0.5);
    width: 250px;
    height: 70px;
    line-height: 70px;
    cursor: pointer;
    transition: .3s;
}

@media (max-width:520px) {
    .header-text {
        font-size: 1.5rem;
    }

    .shop-now>h3 {
        font-size: .8rem;
        width: 210px;
        height: 50px;
        line-height: 50px;
    }
}

a {
    text-decoration: none;
    color: inherit;
}

.shop-now>h3:hover {
    background: rgba(34, 0, 0, 0.8);
}

/* featured products */
.featured-products-section {
    width: 100vw;
    height: calc(100vh - 50vh - 60px - 60px);
    margin-top: 20px;
    /* border: 2px solid red; */

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.featured-products-header {
    font-size: .8rem;
    text-align: center;

}

.featured-products-container {
    /* border: 2px solid rebeccapurple; */
    max-width: 650px;
    padding: 0 .5em;
    height: 70%;

    display: flex;
    justify-content: center;
    column-gap: 1em;
}

.single-featured-products {
    width: 190px;
}

.single-featured-products-child-container {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: space-around;
}

.single-featured-products-child-container>.image-container {
    height: 100%;
}

.image-container>img {
    height: 100%;
    overflow: hidden;
}


/* featured products  Ends*/

/* -----------image modal------------- */
.image-modal-wrapper {
    text-align: center;
    overflow: hidden;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    display: flex;
    justify-content: space-around;
    align-items: center;

    transition: .4s;

}

.modal-image {
    height: 96%;
    opacity: 0.99;
}

.cancel-modal {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
    border-radius: 15%;
    color: red;
    background-color: rgba(0, 0, 0, 0.7);
    transition: .5s;
}

.cancel-modal:hover {
    border-radius: 50%;
}

/* products section */

.products-section {
    width: 100%;
    min-width: 300px;
    min-height: 300px;
    margin-top: 2em;
    padding: 2em 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 40px;
}

.products-section-header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.products-section-header>h1 {
    text-align: center;
    width: 250px;
    border-bottom: 4px solid red;
}

.products-wrapper {
    width: 80%;
    min-width: 300px;
    height: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 2em;

}

@media (max-width:990px) {
    .products-wrapper {
        width: 95%;
    }
}

@media (max-width:835px) {
    .products-wrapper {
        width: 80%;
    }
}

@media (max-width:670px) {
    .products-wrapper {
        width: 95%;
    }
}

@media (max-width:300px) {
    .products-wrapper {
        width: 70%;
        min-width: 250px;
    }
}

.single-product-wrapper {
    width: 25%;
    height: 300px;
    padding: .2em .5em;
}

@media (min-width:1308px) {
    .single-product-wrapper {
        max-width: 300px;
        min-width: 260px;
    }
}

@media (max-width:1308px) {
    .single-product-wrapper {
        width: 33.33%;
        min-width: 260px;
    }
}

@media (max-width:835px) {
    .single-product-wrapper {
        width: 50%;
        min-width: 180px;
        height: 260px;
    }
}

@media (max-width:400px) {
    .single-product-wrapper {
        width: 100%;
    }
}

.single-product {
    width: 100%;
    height: 100%;
    padding: .5em 0;
    box-shadow: 0 0 10px .01px gray;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.single-product>.image {
    width: 90%;
    height: 70%;

    text-align: center;
}

.single-product>.image>img {
    height: 100%;
}

.single-product>.image>img:hover {
    cursor: pointer;
}

.divider {
    border: 1px solid black;
    width: 100%;
}

.product-details-wrapper {
    width: 100%;
    height: 28%;
    padding: .5em 0;


    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.product-details {
    width: 100%;
    padding: 0 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.to-cart-btn {
    background-color: rgba(255, 0, 0, 0.9);
    color: white;
    padding: .5em 1em;
    margin-top: .5em;
    border: none;
    cursor: pointer;
    transition: .4s;
}

.to-cart:hover {
    background-color: rgba(255, 0, 0, 0.7);
}

/* ------------------------ */
.parent-container {
    margin-top: 60px;
}

/* ------------------------ */

/* cart stylings */
#cart {
    width: 100%;
    height: calc(100vh - 60px);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    position: fixed;
}

.cart-container {
    width: 750px;
    /* max-height: 90%; */
    padding: 0 1.5em 60px;
    border: 2px solid red;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    row-gap: 1em;

    overflow-y: scroll;
    box-shadow: 0 0 10px 0.01px rgba(0, 0, 0, 0.2);

}


.cart-container>.single-item-span-across {
    width: 100%;
    height: 150px;
    border-bottom: thin solid rgba(0, 0, 0, 0.2);
    padding: .2em 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.image-details {
    float: left;
    width: 300px;
    height: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: center;
}

@media (max-width:750px) {
    .cart-container {
        width: 550px;
    }

    .image-details {
        width: 250px;
    }
}

.cart-item-image {
    height: 100%;
    width: 140px;
    overflow: hidden;

    position: relative;
}

@media (max-width:750px) {
    .cart-item-image {
        width: 90px;
    }
}

.cart-item-image>img {
    width: 100%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.image-details>.details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: 1em;

    margin-left: 1.5em;
}

.remove-item-btn {
    background: red;
    color: white;
    cursor: pointer;
    padding: .5em .7em;
}

.description {
    width: 200px;
    height: 100%;
    overflow: hidden;
    text-align: justify;
    line-height: 1.4em;
}

@media (max-width:750px) {
    .description {
        width: 180px;
    }
}

@media (max-width:580px) {
    .cart-container {
        width: 95%;
    }

    .description {
        display: none;
    }
}


.qty {
    font-size: 1.3em;
    text-align: center;
}

.qty>h5 {
    text-align: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

/* -------------------------- */